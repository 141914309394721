/* LAYOUT CSS */
.cover {
  background-color: $background-color;
  // position: fixed;
  text-align: center;
  width: 100%;
  min-height: 400px;
  padding: 30px 15px 20px;
  z-index: 1;
  @include media-query($medium-screen) {
    background-position: center;
    padding: 30px;
  }
  @include media-query($large-screen) {
    height: 100vh;
    position: initial;
    width: 100%;
  }
}
.cover-card {
  color: $text-color;
  padding: 0 30px;
  .avatar {
/*    border-radius: 50%;*/
    max-width: 250px;
    /*height: 100px;*/
    float: none;
  }
  .author_name {
    display: block;
    color: #F36170;
    font-size: 1.75em;
    text-transform: lowercase;
  }
  .author_job {
    display: block;
    color: $secondary;
    font-size: 0.8em;
    font-weight: 700;
    text-transform: uppercase;
    margin: -5px auto 10px;
  }
  .author_bio {
    font-size: 90%;
    display: block;
		@include media-query($small-screen) {
			margin-bottom: 50px;
		}
  }
  @include media-query($large-screen) {
    text-align: right;
    margin: 0 auto;
    padding: 0 0px 80px;
  }
}
.social-links {
  margin-top: 0.5em;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      display: inline-block;
      margin-left: 1em;
      a.social-link-item {
        color: $secondary;
        &:hover,
        &:focus {
          color: lighten($secondary, 14%);
        }
      }
    }
  }
}

.nav-list, .nav-list-meta {
  margin: 0;
  padding: 0;
  list-style: none;
  .nav-item {
    zoom: 1;
    &:after {
      content: '/';
    }
    &:last-child:after {
      content: '';
    }
    & a {
      margin-top: 1em;
      text-transform: lowercase;
      font-size: 85%;
      color: $warning;
      &:hover,
      &:focus {
				color: lighten($warning, 14%);
      }
    }
  }
}




.nav-list .nav-item {
	& a {
		font-size: 1.1em;
		text-transform: uppercase;
		color: $secondary;
		font-weight: 700;
		&:hover,
		&:focus {
			color: lighten($secondary, 14%);
		}
	}
	&:after {
		content: '';
	}
	@include media-query($tiny-screen) {
		display: inline-block;
		*display: inline;
		&:after {
			content: '\2015';
		}
		&:last-child:after {
			content: '';
		}
	}
}
.nav-list-meta .nav-item {
	display: inline-block;
	*display: inline;
}



.lizenzpic
{
	margin-left: 5px;
}



.image-wrapper {
	text-align: center;
/* 	border: 1px solid #aaa; */
	background-color: #fff;
	@include media-query($small-screen) {
		margin: 8px;
		padding: 5px;
	}
}
.image-caption {
	color: #999;
	margin: 10px;
}
.alignright {
	@include media-query($small-screen) {
	float: right;
	}
}
.alignleft {
/* 	float: left; */
}
.floatleft {
 	float: left; 
}
.aligncenter figure
{
	display:block;
	margin:0 auto 21px auto;
}

figure
{
	display: table;
	@include media-query-reverse($small-screen) {
		margin: 1em 0 !important;
	}
	
}
figcaption
{
	display: table-caption;
	caption-side:bottom;
	color: #999;
	margin: 8px;
}

.page,
.post,
#post
{
	@include media-query($larger-screen) {
		max-width: 1100px;
	}
	& .post-footer {
		margin: 25px 8px;
		border-top: 1px solid #f5f5f5;
		padding-top: 20px;
	}
	
}



.authorcard {
	float: left;
	border: 1px solid #666;
	padding: 5px;
	background-color: #fafafa;
	font-size: 90%;
	margin: 15px 8px;
	max-width: 534px;
	& h4 {
		margin: 0;
	}
	& .authorabout {
		font-size: 90%;
		margin: 0;
	}
	& .authorpic {
		float: left;
		max-width: 70px;
		margin-right: 20px;
	}
}

.authorlistcard {
	border: 1px solid #666;
	padding: 5px;
	background-color: #fafafa;
	font-size: 90%;
	margin: 15px 0;
  display:block;
	& .authorabout {
		font-size: 90%;
		margin: 0;
	}
	& .authorpic {
		float: left;
		max-width: 100px;
		margin-right: 20px;
	}
}
