/**
  * Helpers
  */

// Displays
.block { display: block }
.inline-block { display: inline-block }

.hidden-md {
  display: none;
  @include media-query($large-screen) {
    display: inline-block;
  }
}

.radius-box {
  border-radius: 10px;
}

.radius-ls {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.radius-rs {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.radius-l1 {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.radius-r1 {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

// Margin helpers
.m0            { margin: 0 }
.mt0           { margin-top: 0 }
.mr0           { margin-right: 0 }
.mb0           { margin-bottom: 0 }
.ml0           { margin-left: 0 }

.ms            { margin: .25em }
.mts           { margin-top: .25em }
.mbs           { margin-bottom: .25em }
.mrs           { margin-right: .25em }
.mls           { margin-left: .25em }

.mm            { margin: .5em }
.mtm           { margin-top: .5em }
.mbm           { margin-bottom: .5em }
.mrm           { margin-right: .5em }
.mlm           { margin-left: .5em }

.m1            { margin: $base-space-unit }
.mt1           { margin-top: $base-space-unit }
.mb1           { margin-bottom: $base-space-unit }
.mr1           { margin-right: $base-space-unit }
.ml1           { margin-left: $base-space-unit }

.m2            { margin: $base-space-unit*2 }
.mt2           { margin-top: $base-space-unit*2 }
.mb2           { margin-bottom: $base-space-unit*2 }
.mr2           { margin-right: $base-space-unit*2 }
.ml2           { margin-left: $base-space-unit*2 }

// Padding helpers
.p0            { padding: 0 }
.pt0           { padding-top: 0 }
.pr0           { padding-right: 0 }
.pb0           { padding-bottom: 0 }
.pl0           { padding-left: 0 }

.ps            { padding: .25em }
.pts           { padding-top: .25em }
.pbs           { padding-bottom: .25em }
.prs           { padding-right: .25em }
.pls           { padding-left: .25em }

.pm            { padding: .5em }
.ptm           { padding-top: .5em }
.pbm           { padding-bottom: .5em }
.prm           { padding-right: .5em }
.plm           { padding-left: .5em }

.p1            { padding: 1em }
.pt1           { padding-top: 1em }
.pr1           { padding-right: 1em }
.pb1           { padding-bottom: 1em }
.pl1           { padding-left: 1em }

.p2            { padding: 1em*2 }
.pt2           { padding-top: 1em*2 }
.pr2           { padding-right: 1em*2 }
.pb2           { padding-bottom: 1em*2 }
.pl2           { padding-left: 1em*2 }

.pb5           { padding-bottom: 1em*5 }

// Padding small
.pads           { padding: .25em }

// Padding medium
.padm           { padding: .5em }

// Padding large
.padl           { padding: 1em }

// Padding small left :: right
.padsx {
 padding-left: .25em;
 padding-right: .25em;
}

// Padding medium left :: right
.padmx {
 padding-left: .5em;
 padding-right: .5em;
}

// Padding large left :: right
.padlx {
 padding-left: 1em;
 padding-right: 1em;
}

// Padding small top :: bottom
.padsy {
 padding-top: .25em;
 padding-bottom: .25em;
}

// Padding medium top :: bottom
.padmy {
 padding-top: .5em;
 padding-bottom: .5em;
}

// Padding large top :: bottom
.padly {
 padding-top: 1em;
 padding-bottom: 1em;
}

// Colors helpers
.primary-text-color {
  color: $primary;
}

.primary-bg-color {
  background-color: $primary;
}

.secondary-text-color {
  color: $secondary;
}

.secondary-bg-color {
  background-color: $secondary;
}

.success-text-color {
  color: $success;
}

.success-bg-color {
  background-color: $success;
}

.danger-text-color {
  color: $danger;
}

.danger-bg-color {
  background-color: $danger;
}

.info-text-color {
  color: $info;
}

.info-bg-color {
  background-color: $info;
}

.warning-text-color {
  color: $warning;
}

.warning-bg-color {
  background-color: $warning;
}
